import React, {useState, useCallback, Fragment} from 'react'
import { 
    Tabs,
    Button,
    Stack,
    Icon,
    Modal,
    TextContainer,
} from "@shopify/polaris"
import formatCurrency from '../../../../helpers/formatCurrency'
import {
    DeleteMajor
  } from '@shopify/polaris-icons';
import styled from 'styled-components'
import update from 'immutability-helper'
const ABTestingWrapper = styled.div`
    margin: 20px 0;
    .ab-analytics {
        margin: 0 10px;
    }
    .ab-tabs {
        margin-top: 10px;
        border-top: 1px solid #DFE3E8;
        border-bottom: 1px solid #DFE3E8;
        .version-item {
            text-align: center;
            padding: 15px 0;
            cursor: pointer;
            svg {
                fill: #D72C0D;
                width: 80%
                height: 80%
            }
        }
        .active {
            background: #F2F7FE;
            border-bottom: 2px solid #2C6ECB;

        }
        .b-version {
            background: #F1F8F5;
            border-bottom: 2px solid #008060;
        }
    }
`
const ButtonWrapper = styled.div`
    margin: 20px 0;
    .Polaris-Button {
        background: #2968C0;
        color: white;
    }
    .Polaris-Button:hover {
        background: #0554C3;
    }
`
function ABTesting(props) {
    const {  versionAnalytics, versionData, setVersionData, checkoutOfferType, version, setVersion, state, setState, locale, currency } = props
    
    const [activeA, setActiveA] = useState(false);
    const [activeB, setActiveB] = useState(false);
    const handleAChange = () => setActiveA(!activeA)
    
    const handleBChange = () => setActiveB(!activeB)
    const activatorA = <div onClick={handleAChange}><Icon source={DeleteMajor} color="base"/></div>
    const activatorB = <div onClick={handleBChange}><Icon source={DeleteMajor} color="base"/></div>
    const handleVersionChange = (selectedVersion) => {
            if(selectedVersion === 'A'){
                if("B"===version){
                    const newStateData = {...state, ...versionData, versionB:{
                        layout: state.layout, banner: state.banner, message: state.message,
                        subtitle: state.subtitle, cta: state.cta, compareAtPrice: state.compareAtPrice,
                        product: state.product, variantsTriggers: state.variantsTriggers, ctaStyles: state.ctaStyles,
                        textStyles: state.textStyles, swapPrice: state.swapPrice, generalLayout: state.generalLayout,
                    }}
                    setState(newStateData)
                }
            }else{
                if("A"===version){
                    setVersionData({
                        layout: state.layout, banner: state.banner, message: state.message,
                        subtitle: state.subtitle, cta: state.cta, compareAtPrice: state.compareAtPrice,
                        product: state.product, variantsTriggers: state.variantsTriggers, ctaStyles: state.ctaStyles,
                        textStyles: state.textStyles, swapPrice: state.swapPrice, generalLayout: state.generalLayout,
                    })
                    const newStateData = {...state, ...{
                        layout: state.versionB.layout, banner: state.versionB.banner, message: state.versionB.message,
                        subtitle: state.versionB.subtitle, cta: state.versionB.cta, compareAtPrice: state.versionB.compareAtPrice,
                        product: state.versionB.product, variantsTriggers: state.versionB.variantsTriggers, ctaStyles: state.versionB.ctaStyles,
                        textStyles: state.versionB.textStyles, swapPrice: state.versionB.swapPrice, generalLayout: state.versionB.generalLayout,
                    }}
                    setState(newStateData)
                }

            }
            setVersion(selectedVersion)
            
        }
    const enableABTesting = () => {
     
        setState(update(state, { versionB: { $set: {
            layout: state.layout, banner: state.banner, message: state.message,
            subtitle: state.subtitle, cta: state.cta, compareAtPrice: state.compareAtPrice,
            product: state.product, variantsTriggers: state.variantsTriggers, ctaStyles: state.ctaStyles,
            textStyles: state.textStyles, swapPrice: state.swapPrice, generalLayout: state.generalLayout,
        } }}))
        setVersion("A")
    }
    const removeVersionA = () => {
        if("A" === version){
            setState({...state, ...state.versionB, versionB: null})
        }
        if("B" === version){
            setState({...state, versionB: null})
        }
        setVersion(null)
    }
    const removeVersionB = () => {
        if("A" === version){
            setState({...state, versionB: null})
        }
        if("B" === version){
            setState({...state, ...versionData, versionB: null})
        }
        setVersion(null)
    }
    return (
        state.versionB ?
            <ABTestingWrapper>
                <div className='ab-analytics'>
                <Stack alignment="center" distribution="equalSpacing">
                    <div>
                        <p>Impressions</p>
                        <p>{versionAnalytics&&versionAnalytics[version]?versionAnalytics[version].totalImpressionCount:0}</p>
                    </div>
                    <div>
                        <p>Conversion rate</p>
                        <p>{versionAnalytics&&versionAnalytics[version]?versionAnalytics[version].conversion:'-%'}</p>
                    </div>
                    <div>
                        <p>Revenue</p>
                        <p>{versionAnalytics&&versionAnalytics[version]&&versionAnalytics[version].totalUpsoldRevenue?formatCurrency(versionAnalytics[version].totalUpsoldRevenue,locale,currency):formatCurrency(0, locale, currency)}</p>
                    </div>
                </Stack>
                <div>
                </div>
                <div className='ab-tabs'>
                <Stack alignment="center" distribution="fillEvenly">
                    <div className={"A" === version ? 'version-item active a-version':'version-item'}>
                        <Stack alignment="center" distribution="center">
                        <span onClick={()=>handleVersionChange("A")}>Version A (50%)</span>
                        <Modal
                            activator={activatorA}
                            open={activeA}
                            onClose={handleAChange}
                            title="Remove version"
                            primaryAction={{
                            content: 'Remove version',
                            onAction: removeVersionA,
                            destructive: true,
                            }}
                            secondaryActions={[
                            {
                                content: 'Cancel',
                                onAction: handleAChange,
                            },
                            ]}
                        >
                            <Modal.Section>
                            <TextContainer>
                                <p>
                                This will remove all analytics associated. This cannot be undone.
                                </p>
                            </TextContainer>
                            </Modal.Section>
                        </Modal>
                        </Stack>
                    </div>
                    <div className={"B" === version ? 'version-item active b-version':'version-item'}>
                        <Stack  alignment="center" distribution="center">
                            <span onClick={()=>handleVersionChange("B")}>Version B (50%)</span>
                            <Modal
                                activator={activatorB}
                                open={activeB}
                                onClose={handleBChange}
                                title="Remove version"
                                primaryAction={{
                                content: 'Remove version',
                                onAction: removeVersionB,
                                destructive: true,
                                }}
                                secondaryActions={[
                                {
                                    content: 'Cancel',
                                    onAction: handleBChange,
                                },
                                ]}
                            >
                                <Modal.Section>
                                <TextContainer>
                                    <p>
                                    This will remove all analytics associated. This cannot be undone.
                                    </p>
                                </TextContainer>
                                </Modal.Section>
                            </Modal>
                        </Stack>
                    </div>
                </Stack>
                </div>
                </div>
            </ABTestingWrapper>
          :
            <ButtonWrapper>
                <Button fullWidth onClick={()=>enableABTesting()}>Add an A/B version</Button>
            </ButtonWrapper>
           
          
            
    )
}
export default ABTesting;